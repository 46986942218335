import "./styles.css";
import {ThemeProvider} from '@mui/material/styles'
import React, { useState, useEffect } from "react";
import Intro from "./components/Intro.js";
import Home from "./components/Home.js";
import theme from "./components/theme.js";
import { CacheProvider } from '@emotion/react';
import * as Constants from "./components/config/config.js";
import * as Globals from "./components/config/GLOBALS.js";
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});



import jwt_decode from "jwt-decode";

import actions from "./components/actions";

function App() {

  const [currentLang, setCurrentLang] = useState(Constants.LANG);
  const [notAllowed, setNotAllowed] = useState(false);
  const [mainPage, setMainPage] = useState(window.location.href.split("https://")[1].split(".")[0]=="govis"?true:false);
  const [underConstruction, setUnderConstruction] = useState((window.location.href.indexOf("2053")!=-1 || window.location.href.indexOf("australia")!=-1  || window.location.href.indexOf("braazil")!=-1 )?false:false);


  useEffect(() => {


    if (Constants.MODE=="TEST")
      {
        setNotAllowed(false)

        return 1;
        /*
        const getIp = async () => {
         // Connect ipapi.co with fetch()
         const response = await fetch("https://ipapi.co/json/")

         const data = await response.json()
          console.log(data)
         // Set the IP address to the constant `ip`

         return data.ip
       }*/
       getIp().then( (result) => {

                            if (result.indexOf(Globals.TEST_ALLOW_IP)==-1)
                            {
                                  alert("Not Allowed")
                                  window.location.href = "https://govis.ai"
                                  setNotAllowed(true)

                            }


       })



     }
 });



  return (
      <div>
       { !underConstruction && !notAllowed && currentLang=="he" && (<CacheProvider value={cacheRtl}>
           <ThemeProvider theme={theme}>
          <div className="App" dir={currentLang=="he"?"rtl":""}>

            <div>

                <Home mainPage={mainPage}/>
            </div>
          </div>
          </ThemeProvider>
        </CacheProvider>)}

        {   !underConstruction &&!notAllowed && currentLang=="en" && (<ThemeProvider theme={theme}>
           <div className="App" dir={currentLang=="he"?"rtl":""}>

             <div>

                 <Home mainPage={mainPage}/>
             </div>
           </div>
           </ThemeProvider>)}

           {underConstruction && <p> Under construction, Please visit https://govis.center </p>}
      </div>

  );
}
export default App;

//416913523020-skl8c263bg31buo05lh3cj6sc02560k0.apps.googleusercontent.com

//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
